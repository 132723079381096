import type {
  Customer,
  CustomerAccessToken,
} from "@/graphql/generated/graphql-operations";
import Client, { Environment, models } from "@/js/lib/membership-client.encore";
import * as Sentry from "@sentry/browser";

type Subscription = models.Subscription;
type Call = models.Call;
interface CustomerStore {
  customer: Customer;
  customerAccessToken: CustomerAccessToken;
}

export type State = {
  isActiveSubscriber: boolean;
  subscription: Subscription | null;
  book_a_vet_call: boolean;
  call: Call | null;
  loading: boolean;
  fetchSubscriptionStatus(): Promise<void>;
  init(): void;
};

const { IS_STAGING, customer: themeCustomer } = useTheme();
const { get } = useLocalStorage();

const customer = get<CustomerStore>("customer");

// Client initialization
const encoreMembershipClient = new Client(
  Environment(IS_STAGING ? "dutch-staging" : "dutch-prod")
);

const customerAccessToken = customer?.customerAccessToken?.accessToken;

async function fetchMembershipData() {
  if (!customerAccessToken || !themeCustomer?.id) return;

  try {
    const response =
      await encoreMembershipClient.memberships.GetMembershipByShopifyCustomerID(
        themeCustomer?.id.toString(),
        {
          HeaderAdminKey: "admin",
          HeaderAuthorization: customerAccessToken!,
        }
      );

    return response;
  } catch (error: any) {
    Sentry.captureException(error);
  }
}

export const subscriptionStore: State = {
  isActiveSubscriber: false,
  subscription: null,
  book_a_vet_call: false,
  call: null,
  loading: false,

  init() {
    this.fetchSubscriptionStatus();
  },

  async fetchSubscriptionStatus() {
    this.loading = true;

    try {
      const membership = await fetchMembershipData();

      if (membership) {
        this.isActiveSubscriber = membership.active || false;
        this.subscription = membership.subscription || null;
        this.book_a_vet_call = membership.book_a_vet_call || false;
        this.call = membership.call || null;
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  },
};
