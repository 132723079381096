// Components
import {
  AnnualGift,
  AnnualRenewal,
  BundleCart,
  CollectionFiltering,
  Collections,
  EmailSubscription,
  InsuranceHandler,
  Login,
  Membership,
  PopupPresidio,
  QuizRecommendProduct,
  Search,
} from "@/alpine/components";

// Stores
import { cart, subscriptionStore, ui } from "./stores";

// Plugins
import collapse from "@alpinejs/collapse";
import focus from "@alpinejs/focus";
import intersect from "@alpinejs/intersect";
import persist from "@alpinejs/persist";
import { Money, Plur, Segment } from "./plugins";

import Alpine from "alpinejs";

// Stores
Alpine.store("ui", ui);
Alpine.store("cart", cart);
Alpine.store("subscription", subscriptionStore);

// Plugins
Alpine.plugin(Money);
Alpine.plugin(focus);
Alpine.plugin(Plur);
Alpine.plugin(persist);
Alpine.plugin(intersect);
Alpine.plugin(collapse);
Alpine.plugin(Segment);

// Components
Alpine.data("collections", Collections);
Alpine.data("membership", Membership);
Alpine.data("search", Search);
Alpine.data("bundleCart", BundleCart);
Alpine.data("quizRecommendProduct", QuizRecommendProduct);
Alpine.data("annualRenewal", AnnualRenewal);
Alpine.data("annualGift", AnnualGift);
Alpine.data("popupPresidio", PopupPresidio);
Alpine.data("emailSubscription", EmailSubscription);
Alpine.data("collectionFiltering", CollectionFiltering);
Alpine.data("insuranceHandler", InsuranceHandler);
Alpine.data("login", Login);

export default Alpine;
