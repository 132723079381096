import * as Sentry from "@sentry/browser";
import type { AlpineComponent } from "alpinejs";

interface LoginState {
  activePanel: "default" | "lite";
  searchParams: URLSearchParams;
  shouldShowJoinNow: boolean;
  redirectToJoinNowIfEligible: () => void;
}

export function Login(): AlpineComponent<LoginState> {
  return {
    // Computed properties
    get searchParams() {
      return new URLSearchParams(window.location.search);
    },
    get shouldShowJoinNow() {
      const meetsActivationCriteria =
        this.searchParams.has("checkout_url") ||
        document.referrer.includes("/collections") ||
        this.searchParams.has("return_to", "/checkout");

      const hasNoExclusionFlags =
        !this.searchParams.has("alt_login", "false") &&
        !document.referrer.includes("/pages/annual-renewal");

      return meetsActivationCriteria && hasNoExclusionFlags;
    },

    // State
    activePanel: "default",

    // Methods
    redirectToJoinNowIfEligible() {
      if (this.shouldShowJoinNow) {
        Sentry?.captureMessage("Join Now redirect triggered", {
          contexts: {
            source: {
              current_url: window.location.href,
              referrer: document.referrer,
              search_params: Object.fromEntries(this.searchParams),
            },
          },
        });
        window.location.href = "/pages/join-now";
      }
    },

    // Init
    init() {
      this.activePanel = this.searchParams.has("lite") ? "lite" : "default";
      this.redirectToJoinNowIfEligible();
    },
  };
}
